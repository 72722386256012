@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .custom-scrollbar {
    scrollbar-color: theme("colors.slate.400") transparent;
  }

  .custom-scrollbar::-webkit-scrollbar {
    width: theme("spacing.sm");
    height: theme("spacing.sm");
  }

  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: theme("colors.slate.400");
    border-radius: theme("borderRadius.full");
  }

  .custom-scrollbar::-webkit-scrollbar-track {
    background: transparent;
  }

  .hotspot {
    width: 42px;
    height: 42px;
    padding: 6px;
    background-color: rgba(0, 0, 0, 0.75);
    color: rgb(255, 255, 255, 0.8);
    border-radius: 100%;
    cursor: pointer;
  }

  .hotspot-active {
    background-color: rgba(93, 0, 179, 0.75) !important;
  }
}
